import Footer from "../constant-screens/footer";
import SignIn from "../half-screens/signin";

function SigninScreen() {
  return (
    <div>
      <SignIn />
      <Footer />
    </div>
  );
}

export default SigninScreen;
