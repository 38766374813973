import Footer from "../constant-screens/footer";
import SignUp from "../half-screens/signup";

function SignupScreen() {
  return (
    <div>
      <SignUp />
      <Footer />
    </div>
  );
}

export default SignupScreen;
